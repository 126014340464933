const ConnectInicio = () => {

    return (

        <div>
            <button onClick={() => {window.location.href = `#`}} className="ConnectInicio">Launch App <p className="commingSoon">(Próximamente)</p></button>
        </div>
)
}

export default ConnectInicio;