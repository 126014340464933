const Caja3 = () => {

    return (
        <div className="Caja3">
            <svg className="imagen3" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_805_16624)">
                <circle cx="32.5" cy="32.5002" r="32.5" fill="#F6BC91"/>
                <circle cx="32.5" cy="32.5002" r="28.0469" stroke="white" strokeOpacity="0.3" strokeWidth="8.90617"/>
                </g>
                <path d="M26.7128 41.0002V37.5682H32.4728C32.8088 37.5682 33.1128 37.4962 33.3848 37.3522C33.6568 37.2082 33.8648 37.0082 34.0088 36.7522C34.1688 36.4962 34.2488 36.2002 34.2488 35.8642C34.2488 35.5442 34.1688 35.2562 34.0088 35.0002C33.8648 34.7282 33.6568 34.5202 33.3848 34.3762C33.1128 34.2162 32.8088 34.1362 32.4728 34.1362H27.5288V30.8482H32.5928C32.9128 30.8482 33.1928 30.7762 33.4328 30.6322C33.6728 30.4882 33.8648 30.2962 34.0088 30.0562C34.1688 29.8162 34.2488 29.5442 34.2488 29.2402C34.2488 28.9362 34.1688 28.6642 34.0088 28.4242C33.8648 28.1842 33.6728 27.9922 33.4328 27.8482C33.1928 27.7042 32.9128 27.6322 32.5928 27.6322H26.7128V24.2002H33.2168C34.1608 24.2002 35.0008 24.4162 35.7368 24.8482C36.4888 25.2802 37.0808 25.8562 37.5128 26.5762C37.9608 27.2962 38.1848 28.0962 38.1848 28.9762C38.1848 29.6322 38.0328 30.2642 37.7288 30.8722C37.4408 31.4802 37.0328 32.0082 36.5048 32.4562C36.8728 32.7442 37.1848 33.0802 37.4408 33.4642C37.6968 33.8322 37.8888 34.2322 38.0168 34.6642C38.1608 35.0802 38.2328 35.5202 38.2328 35.9842C38.2328 36.9282 38.0008 37.7842 37.5368 38.5522C37.0888 39.3042 36.4808 39.9042 35.7128 40.3522C34.9448 40.7842 34.0808 41.0002 33.1208 41.0002H26.7128Z" fill="#262626"/>
                <defs>
                <filter id="filter0_b_805_16624" x="-169.119" y="-169.118" width="403.237" height="403.237" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="84.5593"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_805_16624"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_805_16624" result="shape"/>
                </filter>
                </defs>
            </svg>
            <p className="titulo3">Minimizar Riesgos</p>
            <p className="descripcion3">Reducir significativamente las pérdidas transitorias y evitar riesgos imprevistos gracias a los 'backstops' y a nuestra estrategia de cobertura</p>
        </div>
    )
}

export default Caja3;