import BannerMovil from "./bannerMovil";

const TextoDescripcion = () => {

    return (
        <div className="TextoDescripcion">
            <BannerMovil/>
            <p className="h5">Nuestro Producto</p>
            <svg className="vector7" width="38" height="2" viewBox="0 0 38 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0700684" width="37.4522" height="2" rx="1" fill="#E6007E"/>
            </svg>
            <p className="descripcion">Un protocolo DeFi que se encarga de forma segura de todo el proceso de inversión para que solo tengas que depositar tu stablecoin y disfrutar de los beneficios.<br/><br/> Depositas tus fondos en un pool de stablecoin intercambiándolos por nuestro token, Growi.LP, y esos fondos son asignados entre diferentes pools por el algoritmo Growi.LP para encontrar las oportunidades más rentables.<br/><br/>Growi.LP es transparente y descentralizado, por lo que puedes retirar tus fondos cuando quieras, sin permisos.<br/><a href="https://docs.growi.fi/fundamentals-of-growi.lp-lite-paper/financial-fundamentals" className="enlace"><svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5541 0L6.93631 6L8.18472 7.33333L12.5541 2.66667L16.2994 6.66667L11.9299 11.3333L13.1783 12.6667L18.7962 6.66667L12.5541 0Z" fill="#E6007E"/>
                <path d="M0.0700684 13.3334L5.6879 7.33338L6.93631 8.66671L2.56688 13.3334L6.31211 17.3334L10.6815 12.6667L11.9299 14L6.31211 20L0.0700684 13.3334Z" fill="#E6007E"/>
                <path d="M11.9299 6.00003L5.68791 12.6667L6.93631 14L13.1783 7.33336L11.9299 6.00003Z" fill="#E6007E"/>
                </svg>
                &nbsp;&nbsp;¿Quieres saber más?</a></p>
            
        </div>
    )
}

export default TextoDescripcion;