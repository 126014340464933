import React from 'react';
import Inicio from './Inicio';
import Error from './Error'
import { BrowserRouter, Route, Routes } from "react-router-dom";


function App() {

    return(

      <div>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Inicio />} />
            </Routes>
          </BrowserRouter>
        </div>

    )
  
  

}

export default App;