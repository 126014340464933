const Caja2 = () => {

    return (
        <div className="Caja2">
             <svg className="imagen2" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_805_16631)">
                <circle cx="32.5" cy="32.5002" r="32.5" fill="#F6BC91"/>
                <circle cx="32.5" cy="32.5002" r="28.0469" stroke="white" strokeOpacity="0.3" strokeWidth="8.90617"/>
                </g>
                <path d="M30.0477 41.0002C29.3597 41.0002 28.7517 40.8562 28.2237 40.5682C27.6957 40.2642 27.2877 39.8642 26.9997 39.3682C26.7117 38.8562 26.5677 38.2962 26.5677 37.6882C26.5677 37.1442 26.7037 36.6162 26.9757 36.1042C27.2637 35.5922 27.6397 35.1682 28.1037 34.8322L33.1437 31.2322C33.4637 31.0082 33.7037 30.7442 33.8637 30.4402C34.0397 30.1202 34.1277 29.7922 34.1277 29.4562C34.1277 29.0722 34.0397 28.7522 33.8637 28.4962C33.7037 28.2242 33.4717 28.0162 33.1677 27.8722C32.8797 27.7122 32.5277 27.6322 32.1117 27.6322H27.3837V24.2002H32.4957C33.5997 24.2002 34.5597 24.4402 35.3757 24.9202C36.2077 25.3842 36.8477 26.0162 37.2957 26.8162C37.7597 27.6002 37.9917 28.4802 37.9917 29.4562C37.9917 30.4162 37.7917 31.2722 37.3917 32.0242C37.0077 32.7602 36.3757 33.4162 35.4957 33.9922L30.9837 37.0162C30.9517 37.0322 30.9117 37.0722 30.8637 37.1362C30.8317 37.1842 30.8157 37.2402 30.8157 37.3042C30.8157 37.4002 30.8397 37.4722 30.8877 37.5202C30.9517 37.5522 31.0397 37.5682 31.1517 37.5682H38.3997V41.0002H30.0477Z" fill="#262626"/>
                <defs>
                <filter id="filter0_b_805_16631" x="-169.119" y="-169.118" width="403.237" height="403.237" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="84.5593"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_805_16631"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_805_16631" result="shape"/>
                </filter>
                </defs>
            </svg>
            <p className="titulo2">Maximizar Rentabilidad</p>
            <p className="descripcion2">Escaneamos automáticamente cientos de pools que cumplen nuestros criterios de seguridad para encontrar el máximo beneficio en tiempo real</p>
        </div>
    )
}

export default Caja2;