
const AboutUs = () => {

    return (

        <div style={{width: 253, height: 192}} className="AboutUs">
            <p className="titulo">Sobre nosotros</p>
            <ul className="lista">
                <li>
                    <svg style={{marginTop: 5, position: "absolute"}} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <a className="enlacesFooter" href="" target="_blank"> E-mail</a>
                </li>
                <li>
                    <svg style={{marginTop: 5, position: "absolute"}} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <a className="enlacesFooter" href="https://github.com/Growi-DeFi?tab=repositories" target="_blank"> Github</a>
                </li>
                <li>
                    <svg style={{marginTop: 5, position: "absolute"}} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <a className="enlacesFooter" href="" target="_blank"> Colaboradores (próx.)</a>
                </li>
                <li>
                    <svg style={{marginTop: 5, position: "absolute"}} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <a className="enlacesFooter" target="_blank"> Instituciones (próx.)</a>
                </li>
            </ul>
        </div>

)
}

export default AboutUs;